import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FieldBrandIcon from '../../icons/FieldBrandIcon'

const Breadcrumb = ({
  items = [],
  linkRenderer
}) => {
  const itemsWithHome = [
    {
      path: '/',
      title: 'Home',
      className: 'fb-breadcrumb__item--home'
    },
    ...items
  ]

  return items.length && (
    <div className='fb-breadcrumb'>
      <div className='fb-breadcrumb__content'>
        {itemsWithHome.map(({
          title,
          path,
          className
        }, index) => {
          const isLastItem = index === itemsWithHome.length - 1

          return (
            <span
              key={path + title}
              className={classnames(
                'fb-breadcrumb__item',
                className
              )}
            >
              {path
                ? linkRenderer(
                  {
                    to: path,
                    className: 'fb-breadcrumb__link',
                    children: title
                  }
                ) : (
                  <span className='fb-breadcrumb__label'>
                    { title }
                  </span>
                )
              }
              {!isLastItem && (
                <FieldBrandIcon color='black' className='fb-breadcrumb__separator' width='6px' />
              )}
            </span>
          )
        })}
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  /**
   * The breadcrumb elements
   */
  items: PropTypes.array.isRequired,

  /**
   * Pass to make breadcrumb links use a custom link renderer, .e.g. Link.
   * Must have `to`, `className`, `children`.
   */
  linkRenderer: PropTypes.func
}

Breadcrumb.defaultProps = {
  linkRenderer: ({ to, className, children }) => (
    <a href={to} className={className}>
      {children}
    </a>
  )
}

export default Breadcrumb
