import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import ContentfulPage from '../components/Page/ContentfulPage'
import Breadcrumb from '../components/Breadcrumb'

class CasePageTemplate extends React.Component {
  render () {
    const casePage = get(this.props, 'data.contentfulCasePage')
    return (
      <ContentfulPage
        casePage
        blocks={casePage.blocks}
        stories={get(this, 'props.data.stories.edges')}
        showSuccessStoriesBottom
        siteName={get(this, 'props.data.site.siteMetadata.siteName')}
        pageTitle={`${get(this, 'props.data.site.siteMetadata.siteName')} / ${casePage.title}`}
        pageUrl={`${get(this, 'props.data.site.siteMetadata.url')}/about/${casePage.slug}`}
        contentAbove={
          <Breadcrumb items={[
            {
              title: 'About',
              path: `/about`
            },
            {
              title: casePage.title
            }
          ]}
          />
        }
      />
    )
  }
}

export default CasePageTemplate

export const pageQuery = graphql`
  query CasePageBySlug($slug: String!) {
    site {
      ...SiteMetaDataFragment
    }
    contentfulCasePage(slug: { eq: $slug }) {
      slug
      title
      blocks {
        ...HeroFragment
        ...TextureHeaderFragment
        ...LogoBlockFragment
        ...LongTextBlockFragment
        ...ShortTextBlockFragment
        ...ImageTextBlockFragment
        ...StatsBlockFragment
        ...PullQuoteFragment
        ...CustomBlockFragment
      }
    }
    stories: allContentfulShelfLifeSuccessStory {
      edges { node {
      name,
      pharmacyName,
      quote{quote},
      url,
      picture {
        id,
        fluid(
          quality: 80
          maxWidth: 2800
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp
          }
          file {
          url
          }
        } 
      }
    }
  }
}
`
